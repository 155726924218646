

















































import { Vue, Provide, Component } from "vue-property-decorator";
@Component({})
export default class Tarbar extends Vue {
  @Provide() a = "";

  private goTo(val: string): void {
    this.$router.push(val);
  }
}
