


































































import { Vue, Component, Provide } from 'vue-property-decorator';
import Tarbar from '@/components/tarbar.vue';
import serve from '@/serve/mine';
import Cookies from 'js-cookie';
@Component({
  components: {
    Tarbar,
  },
})
export default class Mine extends Vue {
  userInfo = {};
  messageNum = 0;
  @Provide() headImg = require('@/assets/img/head.jpg');
  @Provide() defaultImg = require('@/assets/img/Hd.png');
  created() {
    this.getuserInfo();
    this.getNoRead();
    setInterval(() => {
      this.getNoRead();
    }, 1000 * 60 * 5);
  }
  async getuserInfo() {
    const res = await serve.getUserInfo();
    if (res.data.code === 0) {
      this.userInfo = res.data.data;
      sessionStorage.setItem('userInfo', JSON.stringify(res.data.data));
    }
  }
  getNoRead() {
    serve.getNoRead().then((res) => {
      this.messageNum = res.data.data.moveMessage + res.data.data.sysMessage;
    });
  }
  private goLogin(): void {
    this.$router.push('login');
  }
  private goAccount(): void {
    this.$router.push('/account');
  }
  private logout(): void {
    this.$router.push('/');
    Cookies.remove('token');
  }
  private goTo(val: string): void {
    this.$router.push(val);
  }
}
